import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import bulb from "../../assets/images/bulbs.png";
import "./Connect.css";

const Connect = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/contact");
  };

  return (
    <div className="banner my-0">
      <Container fluid className="h-100">
        <Row className="align-items-center h-100 justify-content-around">
          <Col xs="auto" className="d-flex justify-content-center">
            <img src={bulb} alt="Icon" className="banner-image" />
          </Col>
          <Col xs="auto" className="text-white d-flex justify-content-center">
            <h4 className="mb-0 banner-title">
              Innovation that transforms your ideas into reality!
            </h4>
          </Col>
          <Col xs="auto" className="d-flex justify-content-center">
            <Button onClick={handleNavigate} className="connect-btn">
              Let's Connect
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Connect;
