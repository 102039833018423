import { React, useEffect } from "react";
import { FaEye } from "react-icons/fa"; // Import the eye icon
import { Container, Row, Col } from "react-bootstrap";
import cluefinder from "../../assets/images/projects/cluefinder.png";
import gogreen from "../../assets/images/projects/gogreen.png";
import kbsoftware from "../../assets/images/projects/kbsoftware solutions.png";
import kbmart from "../../assets/images/projects/kbmart.png";
import peoplewin from "../../assets/images/projects/people win.png";
import kbcafe from "../../assets/images/projects/kbcafe.png";
import kbtt from "../../assets/images/projects/kbtt.png";
import cluefinderlogo from "../../assets/images/projects/cluefinder logo.png";
import gogreenlogo from "../../assets/images/projects/gogreen logo.png";
import kbcafelogo from "../../assets/images/projects/kbcafe logo.png";
import kbmartlogo from "../../assets/images/projects/kbmart logo.png";
import kbsoftwarelogo from "../../assets/images/projects/kbsoftware logo.png";
import peoplewinlogo from "../../assets/images/projects/peoplewin logo.png";
import clients from "../../assets/images/projects/clients.png";
import "./Projects.css";

const Projects = () => {
  const projectData = [
    {
      img: kbcafe,
      logo: kbcafelogo,
      title: "KB Cafe",
      link: "https://www.kbcafe.online/",
      description:
        "KB Cafe is a modern, customer-focused café that brings a unique blend of comfort, taste, and creativity to the heart of Zirakpur. Designed as a welcoming space for coffee lovers, casual diners, and remote workers and many more.",
    },
    {
      img: kbsoftware,
      logo: kbsoftwarelogo,
      title: "KB Software Solutions",
      link: "https://kbsoftwaresolutions.com/",
      description:
        "At KB Software Solutions, we are dedicated to transforming your business through innovative technology.We specialize in providing custom software development, mobile applications, and GST solutions tailored to meet the unique needs of your organization.",
    },
    {
      img: kbtt,
      logo: kbsoftwarelogo,
      title: "Kb Tour & Travel",
      link: "https://kbtrip.in/",
      description:
        "Travel is the only thing you buy that makes you richer. At KB Tour & Travel, we wholeheartedly believe in this philosophy and are dedicated to turning your travel dreams into reality. We offer exceptional travel experiences through our thoughtfully designed holiday packages.",
    },
    {
      img: kbmart,
      logo: kbmartlogo,
      title: "Kb Mart",
      link: "https://kharidobefikar.com/",
      description:
        "KB Mart  e-commerce site is a convenient online platform designed to offer customers an easy way to shop for groceries, home improvement items, household essentials, and more. Powered by Kharido Befikar.",
    },
    {
      img: cluefinder,
      logo: cluefinderlogo,
      title: "Clue finder",
      link: "https://cluefinder.in/",
      description:
        "Clue finder Detective Agency is a professional investigation and detective service provider that specializes in solving a wide range of cases.Clue finder focuses on offering discreet and efficient services to both individuals and businesses.",
    },
    {
      img: peoplewin,
      logo: peoplewinlogo,
      title: "People Win",
      link: "https://gogreen.org.in/",
      description:
        "PEOPLEWIN offers skill-based games and ensuring fairness and safety. As a member of IEIC, PEOPLEWIN  the integrity of all games on the platform. Fraudulent play is restricted through advanced fraud detection mechanisms.",
    },
    {
      img: gogreen,
      logo: gogreenlogo,
      title: "Go Green",
      link:"https://gogreen.org.in/",
      description:
        "At Go Green, we believe in a sustainable future,committed to promoting environmentally friendly practices through our community-driven initiative. Under the guidance of Nature Guard Agrotech Innovations Pvt. Ltd.",
    },
    {
      img: clients,
      title: "5000+ Clients", // No logo or description
    },
  ];

  // Split project data
  const ourProjects = projectData.slice(0, 4); // First 4 projects
  const clientProjects = projectData.slice(4); // Last 3 projects

  useEffect(() => {
    const elements = document.querySelectorAll(".projects-heading");
    elements.forEach((element) => {
      element.classList.add("animate-text-move");
    });
  });

  return (
    <Container className="project-container">
      {/* Our Projects Section */}
      <div className="projects-heading text-center">
        <h2 className="project-heading-h2">
          Our <span className="heading-projects-span">Projects</span>
        </h2>
      </div>
      <Row className="projects-card">
        {ourProjects.map((project, index) => (
          <Col xs={12} md={6} lg={4} className="mb-4" key={index}>
            <div className="project-card shadow">
              <div className="project-img-container mb-3">
                <img
                  src={project.img}
                  alt={project.title}
                  className="project-img img-fluid"
                />
                <button
                  onClick={() => window.open(project.link, "_blank")}
                  className="view-button-overlay"
                  aria-label={`View ${project.title}`}
                >
                  <FaEye />
                </button>
                <div className="project-logo-container">
                  <img
                    src={project.logo}
                    alt={project.title}
                    className="project-logo img-fluid"
                  />
                </div>
              </div>
              <div className="project-des p-3">
                <h4>{project.title}</h4>
                <p>{project.description}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      {/* Client Projects Section */}
      <div className="projects-heading text-center mt-5">
        <h2 className="project-heading-h2">
          Our <span className="heading-projects-span">Client Projects</span>
        </h2>
      </div>
      <Row className="projects-card">
      {clientProjects.map((project, index) => (
        <Col
          xs={11}
          md={6}
          lg={4}
          className={`mb-4 ${index === clientProjects.length - 1 ? "last-project-card" : ""}`}
          key={index}
        >
          <div className="project-card shadow">
            <div className="project-img-container mb-3">
              <img
                src={project.img}
                alt={project.title}
                className="project-img img-fluid"
              />
               <button
                  onClick={() => window.open(project.link, "_blank")}
                  className="view-button-overlay"
                  aria-label={`View ${project.title}`}
                >
                  <FaEye />
                </button>
              <div className="project-logo-container">
                {project.logo && (
                  <img
                    src={project.logo}
                    alt={project.title}
                    className="project-logo img-fluid"
                  />
                )}
              </div>
            </div>
            <div className="project-des p-3">
              {project.description ? (
                <>
                  <h4 className={index === clientProjects.length - 1 ? "last-project-title" : ""}>
                    {project.title}
                  </h4>
                  <p>{project.description}</p>
                </>
              ) : (
                <h4 className={index === clientProjects.length - 1 ? "last-project-title" : ""}>
                  {project.title}
                </h4>
              )}
            </div>
          </div>
        </Col>
      ))}
    </Row>    
    </Container>
  );
};

export default Projects;
