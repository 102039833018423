import Jagjot from "../../assets/images/Employee images/JagjotSingh.png";
import Parneet from "../../assets/images/Employee images/ParneetKaur.png";
import Sonali from "../../assets/images/Employee images/SonaliRai.png";
import Rajni from "../../assets/images/Employee images/Rajni pundir.png";
import Kunal from "../../assets/images/Employee images/KunalDhiman.png";
import Muskan from "../../assets/images/Employee images/MuskanSaini.png";
import Vikas from "../../assets/images/Employee images/VikasSharma.png";
import "./Teams.css";

const TeamData = [
  {
    img: Parneet,
    name: "Parneet Kaur",
    degination: "Project Manager",
    exp: "4 years of experience",
  },
  {
    img: Sonali,
    name: "Sonali Kumari",
    degination: "Senior Software Developer",
    exp: "Over 2 years of experience",
  },
  {
    img: Jagjot,
    name: "Jagjot Singh",
    degination: "SEO & SMO Executive",
    exp: "Over 2 years of experience",
  },
  {
    img: Kunal,
    name: "Kunal Dhiman",
    degination: "Graphics Designer",
    exp: "2 year of experience",
  },
  {
    img: Vikas,
    name: "Vikas Sharma",
    degination: "Software Developer",
    exp: "1 year of experience",
  },
  {
    img: Rajni,
    name: "Rajni Pundir",
    degination: "Accountant",
    exp: "3 years of experience",
  },
];

const Teams = () => {
  // const [hoverCard, setHoverCard] = useState(null);

  // const handleMouseEnter = (index) => setHoverCard(index);
  // const handleMouseLeave = () => setHoverCard(null);

  return (
    <>
      <div className="team-header-heading mt-4">
        <h3>
          Meet Our <span>Team</span>
        </h3>
        <p>Explore the talented individuals behind our success.</p>
      </div>
      <div class="team-section">
        {TeamData.map((team, index) => {
          return (
            <div class="team-card mb-30" key={index} style={{ width: "10rem" }}>
              <div class="slide slide1">
                <div class="team-content">
                  <div class="team-icon">
                    <img className="team-images" src={team.img} alt="team" />
                  </div>
                </div>
              </div>

              <div class="slide slide2 mb-2">
                <div class="team-content">
                  <h2>{team.name}</h2>
                  <p>{team.degination}</p>
                  <p>{team.exp}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Teams;
