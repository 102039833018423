import React, { useState } from "react";
import axios from "axios"; 
import "./Product.css"; 
import icon1 from "../../assets/images/product-icons/icon.png";
import jawelry from "../../assets/images/product-icons/jewelry.png";
import icon3 from "../../assets/images/product-icons/icon3.png";
import hotel from "../../assets/images/product-icons/hotel.png";
import icon6 from "../../assets/images/product-icons/icon6.png";
import kbemp from "../../assets/images/product-icons/empare.png";
import kblab from "../../assets/images/product-icons/kbkab.png";
import kbmilk from "../../assets/images/product-icons/kbmilk.png";
import kbschool from "../../assets/images/product-icons/kbschool.png";
import medical from "../../assets/images/product-icons/medical.png";
import moneychash from "../../assets/images/product-icons/moneycash.png";
import kbpro from "../../assets/images/product-icons/kbpro.png";
import kblib from "../../assets/images/product-icons/kblibrabry.png";
import store from "../../assets/images/product-icons/store2.png";
import rapid from "../../assets/images/product-icons/rapid.png";
import kbclinic from "../../assets/images/product-icons/kbclinic.png";
import educational from "../../assets/images/product-icons/educational.png";

const productData = [
  {
    icon: icon1,
    title: "KB Plus",
    des: "India's First GST-ready business software for supermarkets, mobile shops, electronics/FMCG dealers, retailers, wholesalers, and manufacturers.",
  },
  {
    icon: jawelry,
    title: "KB Jewellery ",
    des: "Jewellery shop software with stock management, Girvi management, tag printing, real-time rate updates, ordering, sales/purchase, and financial accounting.",
  },
  {
    icon: icon3,
    title: "KB MLM Software",
    des: "Designed to help businesses manage and automate multi-level marketing operations with compensation plan management, commission calculation, and real-time analytics.",
  },
  {
    icon: medical,
    title: "KB Medical",
    des: "Specialized software for pharmaceutical dealers and distributors, offering stock management and expiry calculation for both wholesale and retail chemists.",
  },
  {
    icon: hotel,
    title: "KB Hotel",
    des: "Comprehensive software for hotels and restaurants with KOT management, table booking, room service, billing, stock management, and production tracking.",
  },
  {
    icon: kbclinic,
    title: "KB Clinic",
    des: "Hospital software covering IPD/OPD, billing, accounting, medicine inventory, stock management, advance payments, and balance facilities.",
  },
  {
    icon: icon6,
    title: "KB Inventory",
    des: "Software designed for rice mills and cotton mills, managing customer bills, rate/weight adjustments, and other sheller industry-specific requirements.",
  },
  {
    icon: kbemp,
    title: "KB Empire",
    des: "Software for shuttering and scaffolding stores, enabling accurate rental bills, stock management, and integrated financial accounting control.",
  },
  {
    icon: store,
    title: "KB STORE",
    des: "Apparel and footwear software for retail showrooms, managing stock by size, color, and article, with barcode printing for tags.",
  },
  {
    icon: educational,
    title: "KB Education",
    des: "Software for educational institutions, managing enquiries, admissions, fee payments, libraries, attendance, and timetables for streamlined administration.",
  },
  {
    icon: kblib,
    title: "KB Library",
    des: "Library management software with book records, issue/receipt tracking, sticker printing, library cards, pending books list, book ledger, and user security features.",
  },
  {
    icon: kbmilk,
    title: "KB Milk Man",
    des: "Software for dairy farms to maintain farmer records, calculate FAT-SNF-CLR, generate 10-day bills, manage stock, and track profit/loss.",
  },
  {
    icon: moneychash,
    title: "KB Sigma",
    des: "Sigma software for non-banking financial companies, tracking customer and hypothecated vehicle records, and offering comprehensive management tools.",
  },
  {
    icon: kbschool,
    title: "KB School",
    des: "School and college management software handling enquiry, admission, fee collection, library, attendance, timetable, certificates, ID cards, and reminders.",
  },
  {
    icon: kbpro,
    title: "KB Pro",
    des: "Software for GST billing with sale reports, automatic backups, outstanding reports, ledger management, SMS templates, and more.",
  },
  {
    icon: kblab,
    title: "KB Smart Lab",
    des: "Laboratory software to maintain patient records and generate accurate, customizable test reports based on specific tests and formulations.",
  },
  {
    icon: rapid,
    title: "KB Rapid",
    des: "Cold storage management software that tracks customer stock by lot number and rack, with stock movement and material inward/outward recording.",
  },
];

const Product = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    productName: "",
  });

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
      productName: "",
    }); // Reset form data
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 const handleSubmit = async (e) => {
  e.preventDefault();

  const productName = selectedProduct ? selectedProduct.title : formData.productName;

  const trimmedFormData = {
    ...formData,
    name: formData.name.trim(),
    email: formData.email.trim(),
    phone: formData.phone.trim(),
    message: formData.message.trim(),
    productName: productName.trim(),
  };

  console.log("Submitting form data: ", trimmedFormData);

  if (!trimmedFormData.name || !trimmedFormData.email || !trimmedFormData.phone || !trimmedFormData.message || !productName) {
    alert("Please fill in all fields.");
    return;
  }

  try {
  const response = await axios.post("https://kb-software-backend.onrender.com/api/query", trimmedFormData);
  console.log("Form Data Submitted: ", response.data);
  alert("Your query has been submitted successfully!");
  closeModal();
} catch (error) {
  if (error.response) {
    console.error("Error submitting form data: ", error.response.data);
    alert(`Error: ${error.response.data.error || "There was an error submitting your query."}`);
  } else {
    console.error("Error submitting form data: ", error.message);
    alert("There was an error submitting your query. Please try again later.");
  }
}
};
  return (
    <div className="container my-5">
      <div className="card-container mt-6">
        {/* Static card outside the rotating cards */}
        <div className="static-cards card-wrapper">
          <div className="card-inner">
            <div className="card-front my-0">
              <h4 className="our">
                Our <span>Products</span>
              </h4>
              <p>
                At KB Software, we offer a range of cutting-edge software
                solutions designed to enhance productivity and streamline
                operations. Our products to various industries, ensuring
                tailored functionality to meet your specific needs.
              </p>
            </div>
          </div>
        </div>

        {/* Rotatable cards */}
        {productData.map((productName, index) => (
          <div key={index} className="card-wrapper rotatable-card">
            <div className="card-inner">
              <div className="card-front">
                <img src={productName.icon} alt="Product icon" />
                <h5 className="my-3 title">{productName.title}</h5>
              </div>
              <div className="card-back">
                <h3>{productName.title}</h3>
                <p>{productName.des}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center my-4 mt-4">
  <button
    onClick={() => openModal(null)} // Open modal without a specific product
    style={{
      top: 10,
      marginTop: "20px",
      marginBottom: "-20px",
      backgroundColor: '#052c65', 
      color: 'white', 
      border: 'none', 
      padding: '16px 22px', 
      fontSize: '18px',
      borderRadius: '4px', 
      cursor: 'pointer', 
      fontWeight: 'bold', 
      transition: 'background-color 0.3s', 
    }}
    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#041e4d')} 
    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#052c65')} 
  >
    Contact For Product &nbsp; → {/* Right arrow */}
  </button>
</div>
      {isModalOpen && (
  <div
    style={{
      display: 'flex', 
      position: 'fixed', 
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      alignItems: 'center',
      justifyContent: 'center', 
      zIndex: 50, 
    }}
  >
    <div
      style={{
        backgroundColor: 'white', 
        borderRadius: '8px', 
        padding: '20px', 
        maxWidth: '600px', 
        width: '100%', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
        position: 'relative', 
      }}
    >
      <button
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          color: '#052c65', 
          fontSize: '24px', 
          cursor: 'pointer', 
          background: 'none', 
          border: 'none', 
        }}
      >
        &times;
      </button>
      <h2 style={{ fontSize: '24px', fontWeight: '600', marginBottom: '16px', textAlign: 'center' }}>
  {selectedProduct ? `message for ${selectedProduct.title}` : "Query For Product"}
</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <div style={{ flex: 1, marginRight: '8px' }}>
            <label htmlFor="name" style={{ display: 'block', marginBottom: '8px', fontWeight: '500', color:'black' }}>Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              style={{
                width: '100%',
                padding: '10px', 
                border: '1px solid #052c65', 
                borderRadius: '4px', 
                boxSizing: 'border-box', 
              }}
            />
          </div>
          <div style={{ flex: 1, marginLeft: '8px' }}>
            <label htmlFor="email" style={{ display: 'block', marginBottom: '8px', fontWeight: '500', color:'black' }}>Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              style={{
                width: '100%',
                padding: '10px', 
                border: '1px solid #052c65', 
                borderRadius: '4px',
                boxSizing: 'border-box', 
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <div style={{ flex: 1, marginRight: '8px' }}>
            <label htmlFor="phone" style={{ display: 'block', marginBottom: '8px', fontWeight: '500' , color:'black' }}>Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
              style={{
                width: '100%',
                padding: '10px', 
                border: '1px solid #052c65', 
                borderRadius: '4px', 
                boxSizing: 'border-box', 
              }}
            />
          </div>
          <div style={{ flex: 1, marginLeft: '8px' }}>
            <label htmlFor="productName" style={{ display: 'block', marginBottom: '8px', fontWeight: '500' , color:'black' }}>Select Product</label>
            <select
  id="productName"
  name="productName" 
  value={formData.productName} 
  onChange={handleInputChange}
  required
  style={{
    width: '100%',
    padding: '10px',
    border: '1px solid #052c65',
    borderRadius: '4px',
    boxSizing: 'border-box',
  }}
>
  <option value="">--Select a Product--</option>
  {productData.map((productName) => (
    <option key={productName.title} value={productName.title}>
      {productName.title}
    </option>
  ))}
</select>
          </div>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <label htmlFor="message" style={{ display: 'block', marginBottom: '8px', fontWeight: '500', color:'black'}}>Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
            style={{
              width: '100%', 
              padding: '10px',
              border: '1px solid #052c65',
              borderRadius: '4px', 
              boxSizing: 'border-box', 
              minHeight: '100px', 
            }}
          />
        </div>
        <button
          type="submit"
          style={{
      top: 10,
      backgroundColor: '#052c65', 
      color: 'white', 
      border: 'none', 
      padding: '10px 20px', 
      fontSize: '16px',
      borderRadius: '4px', 
      cursor: 'pointer', 
      fontWeight: 'bold', 
      transition: 'background-color 0.3s', 
    }}
    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#041e4d')} 
    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#052c65')}
  >
          Submit
        </button>
      </form>
    </div>
  </div>
)}
    </div>
  );
};

export default Product;