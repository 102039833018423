import React, { useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { FaMapMarkerAlt, FaClock, FaHeadset } from "react-icons/fa";

const AddressAndContact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Log the form data before sending it
    console.log("Submitting form data: ", formData);
  
    try {
      const response = await fetch("https://kb-software-backend.onrender.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      // Check if the response is OK (status in the range 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
      }
  
      const data = await response.json();
      
      // Log the successful response from the server
      console.log("Response from server: ", data);
  
      setStatusMessage("Your message has been sent successfully!");
      setFormData({ name: "", email: "", phone: "", message: "" }); // Reset form
    } catch (error) {
      console.error("Error submitting form:", error);
      setStatusMessage("There was an error sending your message. Please try again.");
    }
  };

  return (
    <div className="container my-2">
      <Row>
        {/* Left Section - About Us */}
        <Col md={6} xs={12}>
          <h2 className="text-center mb-4">
            About <span style={{ color: "#052c65" }}>Us</span>
          </h2>
          <Row className="mb-4 gy-4">
            <Col md={12}>
              <Card className="flip-card " style={{ height: "100%", border: "none", backgroundColor: "#e9ecef", borderRadius: "10px" }}>
                <Card.Body className="text-center">
                  <FaMapMarkerAlt size={30} color="#007bff" className="mb-2" />
                  <Card.Title>Our Address</Card.Title>
                  <Card.Text>KHARIDO BEFIKAR</Card.Text>
                  <Card.Text>Ofc No. 1429, 14th Floor, Block D, CCC, VIP road, Zirakpur 140603</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <Card className="flip-card" style={{ height: "100%", border: "none", backgroundColor: "#e9ecef", borderRadius: "10px" }}>
                <Card.Body className="text-center">
                  <FaClock size={30} color="#007bff" className="mb-2" />
                  <Card.Title>Office Timing</Card.Title>
                  <Card.Text>Monday to Saturday: 9:00 AM - 6:00 PM</Card.Text>
                  <Card.Text>Sunday: Closed</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <Card className="flip-card" style={{ height: "100%", border: "none", backgroundColor: "#e9ecef", borderRadius: "10px" }}>
                <Card.Body className="text-center">
                  <FaHeadset size={30} color="#007bff" className="mb-2" />
                  <Card.Title>Customer Support</Card.Title>
                  <Card.Text>Available: 24/7</Card.Text>
                  <Card.Text>Call us anytime for assistance!</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        {/* Right Section - Contact Form */}
        <Col md={6} xs={12}>
          <h2 className="text-center mb-4">Contact <span style={{ color: "#052c65" }}>Us</span></h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label className="text-black">Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                style={{ width: '100%', // Full width
                padding: '8px', // Padding inside the input
                border: '1px solid #052c65', // Border color
                borderRadius: '4px', // Rounded corners
                boxSizing: 'border-box', }}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label className="text-black mt-4">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                style={{ width: '100%', // Full width
                padding: '8px', // Padding inside the input
                border: '1px solid #052c65', // Border color
                borderRadius: '4px', // Rounded corners
                boxSizing: 'border-box', }}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label className="text-black mt-4">Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                style={{ width: '100%', // Full width
                padding: '8px', // Padding inside the input
                border: '1px solid #052c65', // Border color
                borderRadius: '4px', // Rounded corners
                boxSizing: 'border-box', }}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label className="text-black mt-4">Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formData.message}
                style={{ width: '100%', // Full width
                padding: '8px', // Padding inside the input
                border: '1px solid #052c65', // Border color
                borderRadius: '4px', // Rounded corners
                boxSizing: 'border-box', }}
                onChange={handleChange}
                rows={6}
                required
              />
            </Form.Group>
            <Button
  type="submit"
  className="mt-3"
  style={{
    backgroundColor: '#052c65', // Set the background color
    border: 'none', // Remove border
    color: 'white', // Set text color to white for contrast
  }}
>
  Send Message
</Button>
          </Form>
          {statusMessage && <div className="mt-3 text-center">{statusMessage}</div>}
        </Col>
      </Row>
    </div>
  );
};

export default AddressAndContact;